import React, {useRef, useState} from 'react';
import { Helmet } from "react-helmet"



export default function Contact () {


    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Code FAQ | Skillmeter</title>

        </Helmet>

        <div className="px-10">

    <div className='mt-4'>
    <h1 className="text-2xl font-bold text-gray-900 sm:text-4xl ">FAQ Programming Tests</h1>
    </div>


    <div className="mt-4">
      <h3 className="text-xl font-bold text-gray-900 mb-4">Environment for the programming languages</h3>

      <table >
              <tr>
                  <td><b>Language</b></td>
                  <td><b>Version</b></td>
              </tr>
              <tr>
                  <td>C++</td>
                  <td>gcc-4.8.1</td>
              </tr>
              <tr>
                  <td>C#</td>
                  <td>mono-2.8</td>
              </tr>
              <tr>
                  <td>Java</td>
                  <td>sun-jdk-1.7.0_25</td>
              </tr>
              <tr>
                  <td>Objective-C</td>
                  <td>gcc-4.5.1</td>
              </tr>
              <tr>
                  <td>Perl</td>
                  <td>perl 5.16.2</td>
              </tr>
              <tr>
                  <td>Python</td>
                  <td>python-3.2.3</td>
              </tr>
              <tr>
                  <td>PHP</td>
                  <td>php 5.4.4</td>
              </tr>
              <tr>
                  <td>Ruby</td>
                  <td>ruby-1.9.3</td>
              </tr>
              <tr>
                  <td>VB.NET</td>
                  <td>mono-2.4.2.3</td>
              </tr>

      </table>
    </div>

    
    <div className='mt-4'>
      <h3 className="text-xl font-bold text-gray-900 ">Memory and time constraints</h3>
          Each code must respect the following conditions:<br />
          <ul>
              <li>compilation time: max 10 seconds</li>
              <li>execution time: max 15 seconds</li>
              <li>memory usage: max 256 MB</li>
          </ul>
    </div>


    <div className="mt-4">
      <h3 className="text-xl font-bold text-gray-900 ">STDIN and STDOUT</h3>
      You can read input from STDIN (Standard Input) and write output to STDOUT (Standard Output). Different languages have different methods of accessing STDIN and STDOUT. 
    </div>



</div>

        </>
    );
} 